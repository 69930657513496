<template>
  <div class="container-fluid stripe-bg px-0" style="transition:.5s all linear" v-if="!$store.getters['auth/isLoggedIn']">
    <bg-slide></bg-slide>
    <home-btn/>
    <div class="login-container">
      <b-aspect :aspect="1" class="circle-login light--text">
        <b-img
          :src="require('@/assets/logo.svg')"
          style="width: 40%"
          class="logo-circle"
        />
        <validation-observer v-slot="{ handleSubmit }">
          <b-form
            @submit.prevent="handleSubmit(loginSubmit)"
            class="mt-0 mt-md-2"
          >
            <h2 class="text-center mb-1 white--text uppercase">
              <b>{{ $t('portal_login') }}</b>
            </h2>
            <h5 class="text-center mb-4 light--text">
              <b>{{ loginPersonal ? $t('personal_user') : $t('gyms') }}</b>
            </h5>
            <b-form-group>
              <validation-provider
                :name="$t('email_address')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="text"
                  id="email"
                  name="email"
                  :placeholder="$t('email_address')"
                  v-model="credentials.email"
                  :size="windowW > 768 ? 'lg' : 'md'"
                  :class="{
                    'form-control': true,
                    'is-danger': errors.length > 0,
                  }"
                  autofocus=""
                >
                </b-form-input>
                <small v-show="errors.length > 0" class="text-danger is-danger">
                  <i class="fa fa-warning"></i> {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                :name="$t('password')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="password"
                  id="password"
                  pill
                  name="password"
                  :placeholder="$t('password')"
                  v-model="credentials.password"
                  :size="windowW > 768 ? 'lg' : 'md'"
                  :class="{
                    'form-control': true,
                    'is-danger': errors.length > 0,
                  }"
                >
                </b-form-input>
                <small v-show="errors.length > 0" class="text-danger is-danger">
                  <i class="fa fa-warning"></i> {{ errors[0] }}
                </small>
              </validation-provider>

              <b-row class="mt-3" style="font-size: 0.875rem">
                <b-col cols="6">
                  <b-form-checkbox
                    id="rememberMe"
                    style="padding-top: 2px"
                    name="rememberMe"
                    v-model="credentials.rememberMe"
                  >
                    {{ $t('remember_me') }}
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right primary--text"
                  style=""
                >
                  <router-link :to="{ path: 'forgottenPassword' }">
                    {{ $t('forgot_password') }}
                  </router-link>
                </b-col>
              </b-row>
            </b-form-group>
            <div class="text-center">
              <b-btn
                type="submit"
                pill
                variant="primary"
                class="btn btn-warning"
                :size="windowW > 768 ? 'lg' : 'md'"
                :disabled="loginSubmitted"
              >
                {{ $t('login') }}
              </b-btn>
            </div>
            <div
              class="
                text-center
                mt-4
                pt-3
                reg-text
                d-none d-md-block
                light--text
              "
              style="
                font-size: 1.2rem;
                border-top: 1px solid rgba(255, 255, 255, 0.2);
              "
            >
              {{ $t('first_time_here') }}
              <router-link :to="{ path: 'registration' }">
                {{ $t('register') }}
              </router-link>
            </div>
          </b-form>
        </validation-observer>
      </b-aspect>
      <div class="text-center mt-5">
        <div v-if="loginPersonal">
     <v-facebook-login
            @login="loginFb"
            :app-id="fbClientId"
            v-model="fbModel"
            @sdk-init="handleSdkInit"
            :text-style="{}"
          >
            <span slot="login">{{ $t('loginFb') }}</span>
          </v-facebook-login>
        </div>

        <div v-if="loginPersonal">
          <b-btn
            @click="loginGoogle"
            :size="windowW > 768 ? 'lg' : 'md'"
            pill
            style=""
            class="btn mt-3 relative google-btn"
          >
            <svg
              class="g-icon"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 439 278"
              style="enable-background: new 0 0 439 278"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M269,113.6c1.5,8,2.3,16.2,2.3,24.6c0,9.4-1,18.6-2.9,27.4c-12.6,59.4-65.4,104-128.5,104
		c-50.1,0-93.6-28-115.8-69.1c-10-18.5-15.7-39.7-15.7-62.3C8.4,65.5,67.3,6.7,139.8,6.7c31.9,0,61.1,11.4,83.9,30.3l-42.6,34.9
		C169.2,64.3,155,60,139.8,60c-34.3,0-63.4,22.1-73.9,52.7c-2.7,8-4.2,16.5-4.2,25.4c0,9.6,1.7,18.9,5,27.4
		c11.1,29.6,39.7,50.7,73.2,50.7c14.4,0,27.9-3.9,39.5-10.7c15.4-9,27.4-23.1,33.7-40h-70.3v-52H214L269,113.6L269,113.6L269,113.6z
		"
                />
              </g>
            </svg>
            {{ $t('loginGoogle') }}
          </b-btn
          >
        </div>
        <div
          class="text-center py-2 mt-5 reg-text d-md-none light--text"
          style="background: black"
        >
          {{ $t('first_time_here') }}
          <router-link :to="{ path: 'registration' }">
            {{ $t('register') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import bgSlide from '@/components/bgSlide';
import homeBtn from '@/components/homeBtn';

export default Vue.extend({
  name: 'login',
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        rememberMe: true,
        grant_type: 'password',
      },
      windowW: 0,
      loginSubmitted: false,
     // FB: {},
      FBUser: {},
      fbModel: {},
      scope: {},
      //gAuth2: {},
      fbClientId: process.env.VUE_APP_FB_CLIENT_ID,
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    };
  },
  computed: {
    loginPersonal() {
      return this.$store.state.auth.loginPersonal;
    },

  },
  components: { bgSlide, homeBtn },
  mounted() {
    this.fbClientId= process.env.VUE_APP_FB_CLIENT_ID
    this.getSizes();

    window.addEventListener('resize', this.getSizes);
  },
  created() {
    this._debug('created login');
    this.checkGapi();
  },
  methods: {
    //google auth

    checkGapi() {
      let this_ = this;
      if (this.gAuth2 !== 'object' || Object.keys(this.gAuth2).length < 1) {
        this.gapiInterval = setInterval(function () {
          if (window.gapi || gapi) {
            clearInterval(this_.gapiInterval);
            this_.initGApi();
          }
        }, 1000); // xx * 1000 milsec
      }
    },
    initGApi() {
      let this_ = this;

      window.gapi.load('auth2', () => {
      /*  this.$store.dispatch('auth/setGoogle', window.gapi.auth2.init({
          client_id: this.googleClientId,
          //cookiepolicy: "single_host_origin",
        })
        );*/
       this.gAuth2 = window.gapi.auth2.init({
          client_id: this.googleClientId,
          //cookiepolicy: "single_host_origin",
        });

      /*  this.$store.state.auth.gAuth2 = window.gapi.auth2.init({
          client_id: this.googleClientId,
          //cookiepolicy: "single_host_origin",
        });*/
      });

    },
    loginGoogle() {
      let this_ = this;
      this.gAuth2.signIn().then(function (user) {
        this_._debug('signed in google', user.getAuthResponse());
        this_.loginSubmit({ grant_type: 'social', guid: user.getAuthResponse()['id_token'] });
      });
    },

    // end google
    handleSdkInit({ FB, scope }) {
     // this.FB = FB;
      this.$store.dispatch('auth/setFB', FB);
      this.scope = scope;
    },
    loginFb() {
      let this_ = this;

      this.$store.state.auth.FBook.api('/me', 'GET', { fields: 'id,name,email,picture' }, (user) => {
        this_.FBUser = user;
        this_.loginSubmit({ grant_type: 'social', fbuid: this.$store.state.auth.FBook.getAccessToken() });
      });

      /*
      this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' }, (user) => {
        this_.FBUser = user;
        this_.loginSubmit({ grant_type: 'social', fbuid: this.FB.getAccessToken() });
      });*/
    },
    getSizes() {
      this.windowW = window.innerWidth;
    },
    loginSubmit(social = null) {
      this.loginSubmitted = true;
      let this_ = this;
      let obj = social !== null ? social : this.credentials;
      this.$store.commit('loader/show');
      apiClient
        .post('api/token', obj)
        .then((response) => {
          this.$store.dispatch('auth/onLogin', response.data).then(() => {
            this.$router.push({ name: 'channels' });
            this.$store.commit('loader/hide');
          });
        })
        .catch((error) => {

          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
          this.$store.commit('loader/hide');
          this.loginSubmitted = false;
        });
    },

  },
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

@keyframes slide {
  from {right:-100%}
  to {right: 0}
}
@keyframes scale0{
      from { transform: scale(.2) translateY(-50%);}
      to{ transform: scale(1) translateY(-50%);}
}
.login-container {
  position: relative;
  top: 50vh;
  transform: translateY(-50%);
 overflow-x:hidden;
transition:1s all linear;
 animation-name: scale0;
  animation-duration: .5s;
  a {
    color: white;
  }
}

.v-facebook-login {
  border-radius: 50rem !important;
  margin: auto;
  font-size: 1.25rem;
  line-height: 1.5;
  max-width: 280px;
  width: 100%;
  justify-content: flex-start !important;

  svg {
    margin-left: 1ch;
  }
}

.google-btn {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  max-width: 280px;
  width: 100%;
  background: #c94130;
  border-color: #c94130;

  .g-icon {
    height: 100%;
    width: 26px;
    fill: white;
    margin-right: 0.8ch;
    margin-left: 1ch;
  }
}

.circle-login {
  position: relative;
  width: 600px;
  height: 600px;
  margin: auto;
  padding: 5%;
  max-width: 100vw;
  max-height: 100vw;
  align-items: center;



  .form-control {
    border: 0;
    border-radius: 2rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $btnprimary;
    background-color: $btnprimary;
  }

  &:before {
    content: "";
    border-radius: 50%;
    /* padding: 20%; */
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #313131e3;

    border: 15px solid #505050;
  }
}

.logo-circle {
  width: 40%;
  margin: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -3%;
  margin-bottom: 4%;
}

@media (max-width: 768px) {
  .logo-circle {
    width: 30vw;
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3vw;
    margin-bottom: 4vw;
  }

  .circle-login {
    padding: 0 9vw;
    box-sizing: border-box;
    max-width: 100%;

    &:before {
      border-width: 2vw;
    }

    h2 {
      font-size: 5vw;
    }

    h5 {
      font-size: 4vw;
    }

    .reg-text {
      font-size: 3.5vw !important;
      margin-top: 0vw !important;
      border: 0 !important;
    }
  }
  .v-facebook-login {
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5;
    justify-content: flex-start;
  }
}
</style>
